<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
        <el-form-item label="">
          <el-input style="width: 150px;" v-model="filters.reportYear"
                    @input="(e) => (filters.reportYear = utils.validForbid(e))" clearable
                    placeholder="年份"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select style="width: 150px;" v-model="filters.reportMonth" placeholder="月份" clearable>
            <el-option
                v-for="item in month"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table id="exportTab" :data="tableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
              @current-change="currentChange" :highlight-current-row='true' :cell-style="rowClass">
      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
      <el-table-column prop="billMonth" label="结算时间（月）" align="center" >
        <template slot-scope="scope">
          <el-button type="text"
                     @click="getProjectListByMonth(scope.row)">{{ yearMonth(scope.row) }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="totalProjectArea" label="总图审面积" align="center" ></el-table-column>
      <el-table-column prop="totalContractAmount" label="年合同金额" align="center"></el-table-column>
      <el-table-column prop="totalActualAmount" label="月实际收入" align="center"></el-table-column>
      <el-table-column prop="totalActualAmountByYear" label="年实际收入" align="center"></el-table-column>
      <el-table-column prop="belongPeriod"  label="收入分类" align="center">
        <template slot-scope="scope">
          {{'-'}}
        </template>
      </el-table-column>
      <el-table-column prop="invoiceStatus"  label="发票" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="onInvoice(scope.row)" :disabled="scope.row.invoiceCount === 0">
            {{ scope.row.invoiceCount }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="projectCount"  label="项目数" align="center">
        <template slot-scope="scope">
          <el-button type="text" :disabled="scope.row.projectCount === 0"
                     @click="getProjectList(scope.row)">{{
              scope.row.projectCount
            }}
          </el-button>
        </template>
      </el-table-column>
<!--      <el-table-column prop="calculateStartDay" width='190' label="日期" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          {{ scope.row.calculateStartDay }} - {{ scope.row.calculateEndDay }}-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="dataCount">
      </el-pagination>
    </el-col>
    <!-- 发票列表 -->
    <el-dialog title="发票列表" :visible.sync="dialog.invoice.show" v-model="dialog.invoice.show"
               :close-on-click-modal="false" width='80%'>
      <el-table
          :data="dialog.invoice.list"
          border
          style="width: 100%"
          v-loading="listLoading"
          ref="multipleTable"
          :highlight-current-row='true'
          :cell-style="rowClass"
      >
        <!--            @current-change='currentInvoiceChange'-->
        <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
        <el-table-column prop="issuerType" label="开票类型" align="center" width='120' show-overflow-tooltip>
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.issuerType == 0">个人</el-tag>
            <el-tag v-if="scope.row.issuerType == 1" type="success">企业</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceTpye" label="发票类型" width='120' align="center" show-overflow-tooltip>
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.invoiceTpye == 0" type="success">全电专票</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceMoney" label="开票金额" width='120' align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invoiceTitle" label="发票抬头" width='120' align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invoiceFax" label="购方税号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="externalPersonPhone" label="手机号" align="center"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="externalPersonEmail" label="邮箱" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="openTime" label="开票时间" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invoiceStatus" label="收款匹配状态" align="center" show-overflow-tooltip>
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.invoiceStatus == 0" type="danger">未匹配</el-tag>
            <el-tag v-if="scope.row.invoiceStatus == 1" type="success">部分匹配</el-tag>
            <el-tag v-if="scope.row.invoiceStatus == 2">全匹配</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
                        <el-button @click.native="dialog.invoice.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 匹配记录 -->
    <el-dialog title="实收金额匹配记录" :visible.sync="dialog.actualAmount.show" v-model="dialog.actualAmount.show"
               :close-on-click-modal="false" width='50%'>
      <el-table
          :data="revenuePaymentList"
          highlight-current-row
          v-loading="listLoading"
          style="width: 100%;"
      >
        <el-table-column prop="tranFlowNo" label="交易流水号" width=""></el-table-column>
        <el-table-column prop="matchAmount" label="匹配金额" width=""></el-table-column>
        <el-table-column prop="tranDate" label="交易时间" width=""></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.actualAmount.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 项目列表 -->
    <el-dialog title="项目列表" :visible.sync="dialog.projectList.show" v-model="dialog.projectList.show"
               :close-on-click-modal="false" width='85.7%'>
      <el-table :data="dialog.projectList.list" border  v-loading="listLoading"
                ref="multipleTable" :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectNo"  label="项目编号" align="center"></el-table-column>
        <el-table-column prop="areaName" width='160' label="（县）区" align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="contractNo" label="关联合同编号" align="center"></el-table-column>
        <el-table-column prop="projectContacts"  label="项目联系人" align="center"></el-table-column>
        <el-table-column prop="projectPhone"  label="联系方式" align="center"></el-table-column>
        <el-table-column prop="projectUnitPrice"  label="项目单价（元）" align="center"></el-table-column>
        <el-table-column prop="projectArea"  label="图审面积（平方米）" align="center"></el-table-column>
        <!-- <el-table-column prop="contractNo" width='160' label="项目金额（元）" align="center"></el-table-column> -->
        <el-table-column prop="ddIdProjectStatusName"  label="项目状态" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.ddIdProjectStatus === 34" >立项</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 35" >上传资料</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 44">初审任务分配</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 45">初审意见填写</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 46">初审意见提交完成</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 47">初审报告签章完成</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 50">上传复核资料</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 51">复核任务分配</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 52">复核意见填写</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 53">复核意见提交完成</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 54">图审报告签章完成</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 55">审结</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 134">初审报告完成</el-tag>
            <el-tag v-if="scope.row.ddIdProjectStatus === 135">复核报告完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="conclusionTime" width='160' label="项目归档日期" align="center"></el-table-column>
        <el-table-column prop="verificationStatus" width='160' label="项目匹配状态" align="center">
          <template slot-scope='scope'>
            <el-tag v-if="scope.row.verificationStatus == 0" type="danger">未匹配</el-tag>
            <el-tag v-if="scope.row.verificationStatus == 1" type="success">部分匹配</el-tag>
            <el-tag v-if="scope.row.verificationStatus == 2">全匹配</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.projectList.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 月汇总列表-->
    <el-dialog title="汇总列表" :visible.sync="dialog.projectListAll.show" v-model="dialog.projectListAll.show"
               :close-on-click-modal="false" width='88.6%'>
      <el-table id="exportTab" :data="dialog.projectListAll.list" border height="620"
                v-loading="listLoading" ref="multipleTable"  @current-change="currentChangeAll"
                :highlight-current-row='true' :cell-style="rowClass">
<!--        <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>-->
        <el-table-column prop="customerName" label="名称" align="center"></el-table-column>
        <el-table-column prop="" label="结算时间（月）" align="center">
          <template slot-scope="scope">
            {{ scope.row.reportYear }} - {{ scope.row.reportMonth }}
          </template>
        </el-table-column>
        <el-table-column prop="totalProjectArea" label="总图审面积" align="center"></el-table-column>
        <el-table-column prop="contractAmount" label="年合同金额" align="center"></el-table-column>
<!--        <el-table-column prop="actualAmountByMonth" label="月实际收入" align="center"></el-table-column>-->
        <el-table-column prop="actualAmountByYear" label="年实际收入" align="center"></el-table-column>
        <el-table-column prop="belongPeriod" label="收入分类" align="center">
          <template slot-scope="scope">
            {{ '审图收入' }}
          </template>
        </el-table-column>
        <el-table-column prop="invoiceStatus" label="发票" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="onInvoice(scope.row)" :disabled="scope.row.invoiceCount === 0">
              {{ scope.row.invoiceCount }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="projectCount" label="项目数" align="center">
          <template slot-scope="scope">
            <el-button type="text" :disabled="scope.row.projectCount === 0" @click="getProjectList(scope.row)">
              {{scope.row.projectCount }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.projectListAll.show = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
  getFinancialReceivableListPage,
  getNotificationListPageByContractIdAndCycleId,
  getPaymentsByNoMatchList,
  addRevenuePaymentDetail,
  getPaymentDetailListPageByCycleId,
  getProjectListByIds,
  getProjectByCycleId,
  getProjectByContractId,
  getAreaDictionaryList,
  getByNamespace,
  getInvoiceList,
  addFinancialInvoice,
  getBillList,
  getBillDetailByMonth,
  getProjectConclusionReportList,
} from '@/api/api'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export default {
  components: { Toolbar },
  data () {
    return {
      dataCount:undefined,
      utils: '',
      addLoading: false,
      buttonList: [],
      needData: '',
      filters: {
        provinceCode: undefined,
        status: undefined,
        contractNo: undefined,
        belongPeriod: undefined,
        reportYear:undefined,
        reportMonth:undefined,
      },
      invoiceOpen: [
        { name: '个人', value: 0 },
        { name: '企业', value: 1 },
      ],
      allowAmountInvoiced: undefined,//
      tableData: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      dialog: {
        // 发票
        invoice: {
          show: false,
          list:[]
        },
        // 开票
        invoiceOpen: {
          show: false
        },
        // 实收金额记录
        actualAmount: {
          show: false
        },
        // 项目数列表
        projectList: {
          show: false,
          list: []
        },
        //汇总
        projectListAll: {
          show: false,
          list: []
        }
      },
      // 所有银行流水凭证
      revenuePaymentData: [],
      paymentName: '',
      revenuePaymentList: [],
      notificationlist: [],
      addForm: {
        revenuePaymentsId: undefined,
        matchAmount: 0,
        financialReceivableCycleId: undefined,
      },
      addRules: {},
      invoiceMatchList: [],//发票
      receivableCycleIds: [],
      needDataAll:'',//汇总某一行
      invoiceList:'',//发票列表
      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],//月份
      exportList: [],
      exportBoolean: false,
      exportName: [
        { header: '结算年', property: 'billYear' },
        { header: '结算月', property: 'billMonth' },
        { header: '总图审面积', property: 'totalProjectArea' },
        { header: '年合同金额', property: 'totalContractAmount' },
        { header: '月实际收入', property: 'totalActualAmount' },
        { header: '年实际收入', property: 'totalActualAmountByYear' },
        { header: '收入分类', property: 'belongPeriod' },
        { header: '发票', property: 'invoiceCount' },
        { header: '项目数', property: 'projectCount' },
      ],
      isExporting: false,
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getDataList();
    this.init();
  },
  methods: {
    yearMonth(row){
      const aaa = row.billYear + "-" + row.billMonth
      console.log(aaa,'aaa')
      return aaa
    },
    validateAmountInvoiced (rule, value, callback) {
      if (this.invoiceApply.amountInvoiced > this.allowAmountInvoiced) {
        callback(new Error('开票金额不能超过可开票金额'))
      } else {
        callback()
      }
    },
    validatePhoneOrEmail (rule, value, callback) {
      // 自定义验证函数
      const phone = this.invoiceApply.phone;
      const email = this.invoiceApply.email;

      if (!phone && !email) {
        callback(new Error('手机号和邮箱至少要填写一个'));
      } else {
        // 清除另一个字段的验证错误信息
        if (this.invoiceApplyRules.phone === 'phone') {
          this.$refs.invoiceApplyRef.clearValidate('email');
        } else if (this.invoiceApplyRules.phone === 'email') {
          this.$refs.invoiceApplyRef.clearValidate('phone');
        }
        callback();
      }
    },


    invoiceOpenColsed () {
      this.dialog.invoiceOpen.show = false;
      this.allowAmountInvoiced = undefined;
      this.getDataList()
      this.needData = undefined
    },
    addInvoice () {
      this.$refs.invoiceApplyRef.validate(valid => {
        if (valid) {
          this.addLoading = true
          let params = {
            companyId: this.needData[0].companyId,//所属公司
            contractId: this.needData[0].contractId,//所属合同
            externalPersonEmail: this.invoiceApply.email,//收件人邮箱
            externalPersonPhone: this.invoiceApply.phone,//收件人手机号
            invoiceFax: this.invoiceApply.taxNumber,//购方税号
            invoiceMoney: this.invoiceApply.amountInvoiced,//开票金额
            invoiceTitle: this.invoiceApply.invoicingHeader,//发票抬头
            receivableCycleIds: this.receivableCycleIds,//所选账单ID组
          }
          addFinancialInvoice(params).then(res => {
            if (res.data.success) {
              this.$message({
                message: res.data.message,
                type: "success"
              });
              this.getDataList();
              this.invoiceOpenColsed()
              this.addLoading = false
            } else {
              this.$message({
                message: res.data.message,
                type: "error"
              });
              this.addLoading = false
              this.invoiceOpenColsed()
            }
          })

        }
      })
    },
    callFunction (item) {
      this[item.func].apply(this, item);
    },
    async init () {
      const [invoiceStatusRes, statusRes] = await Promise.all(
          [getByNamespace({ namespace: 'BizService.Finance.InvoiceStatus' })],
          [getByNamespace({ namespace: 'BizService.Finance.Status' })]
      )
      // if (invoiceStatusRes.data.success) {
      // }
      // if (statusRes.data.success) {
      // }

    },
    // 列表查询接口
    getDataList () {
      var params = {
        billMonth:this.filters.reportMonth,
        billYear:this.filters.reportYear,
        pageIndex:this.pages.pageIndex,
        pageSize: this.pages.pageSize,
      }
      getBillList(params).then(res => {
        var result = res.data
        if (result.success) {
          const response = result.response
          this.dataCount = response.dataCount
          const data = response.data
          this.tableData = data
        }
      })
    },
    // 开票
    onInvoice (row) {
      let params = {
        invoiceIds: row.invoiceIds,//发票ID组（逗号隔开）
        pageIndex: 1,//页码
        pageSize: 40,//每页条数
      }
      getInvoiceList(params).then(res => {
        let result = res.data;
        if (result.success) {
          const response = result.response
          const data = response.data
          this.invoiceList = data
          this.dialog.invoice.list=data
        }
      })
      this.dialog.invoice.show = true;
    },
    //导出汇总
    async exportAll () {
      for (let i = 1; i < ((this.dataCount / this.pages.pageSize) < 1 ? 2 : +Math.ceil(this.dataCount / this.pages.pageSize)) + 1; i++) {
        let params = {
          billMonth: this.filters.reportMonth,
          billYear: this.filters.reportYear,
          pageIndex: i,
          pageSize: this.pages.pageSize,
        }
        const res = await getBillList(params)
        var result = res.data.response.data
        console.log(result,'res')
        if (result){
          result.forEach(item => {
            item['belongPeriod'] = '-'
          })

          this.exportList.push(...result)
          setTimeout(() => {

          }, 100)
        }



      }
      console.log(this.exportList, 'exportList')
    },
    exportToExcel (dataArray, headersMapping) {
      // 从映射中提取自定义表头
      const headers = headersMapping.map(mapping => mapping.header);

      // 从数据中筛选并映射对应属性
      const filteredData = dataArray.map(obj =>
          headersMapping.reduce((acc, mapping) => {
            const { header, property } = mapping;
            if (Object.prototype.hasOwnProperty.call(obj, property)) {
              acc[header] = obj[property];
            }
            return acc;
          }, {})
      );

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: headers });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileName = '应收款汇总报表.xlsx';

      if (window.navigator.msSaveOrOpenBlob) {
        // For IE/Edge browsers
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // For other browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    //导出
    async exportListData () {
      if (this.isExporting) {
        console.log('导出操作仍在进行中...');
        this.$message.error('导出操作仍在进行中...')
        return; // 如果导出仍在进行中，则退出函数
      }
      this.isExporting = true; //（isExporting是一个用于控制按钮状态的变量）
      await this.exportAll()
      setTimeout(() => {
        this.exportToExcel(this.exportList, this.exportName)
        this.isExporting = false;
        this.exportList=[]
      }, 0)

    },
    // 催收通知
    checkRushNoticeList (row) {
      var params = {
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
        ddIdTemplateType: 119,
        contractId: row.contractId,
        cycleId: row.id,
      }
      getNotificationListPageByContractIdAndCycleId(params).then(res => {
        var result = res.data
        if (result.success) {
          this.notificationlist = result.response.data
        }
      })
      this.dialog.urgeNotice.show = true;
    },
    checkWarningNoticeList (row) {
      var params = {
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
        ddIdTemplateType: 120,
        contractId: row.contractId,
        cycleId: row.id,
      }
      getNotificationListPageByContractIdAndCycleId(params).then(res => {
        var result = res.data
        if (result.success) {
          this.notificationlist = result.response.data
        }
      })
      this.dialog.warningNotice.show = true;
    },
    checkActualAmount (row) {
      getPaymentDetailListPageByCycleId({ cycleId: row.id, pageIndex: 1, pageSize: 60 }).then((res) => {
        this.revenuePaymentList = res.data.response.data;
      });
      this.dialog.actualAmount.show = true;
    },
    getProjectList (row) {
      getProjectListByIds({ projectIds: row.projectIds }).then(res => {
        if (res.data.success) {
          this.dialog.projectList.list = res.data.response;
        }
      })
      this.dialog.projectList.show = true;
    },
    getProjectListByMonth (row) {
      let params = {
        reportMonth:row.billMonth,
        reportYear:row.billYear,
      }
      getBillDetailByMonth(params).then(res => {
        if (res.data.success) {
          this.dialog.projectListAll.list = res.data.response;
        }
      })
      this.dialog.projectListAll.show = true;
    },
    addJournalAccount () {
      console.log("111");
      if (this.needData === null || !this.needData.id) {
        this.$message({
          message: '请选择一条数据',
          type: "error"
        });
        return
      }
      this.addForm = {
        financialReceivableCycleId: this.needData.id,
        matchAmount: 0,
        revenuePaymentsId: undefined
      }
      this.dialog.journalAccount.show = true;
    },
    addSubmitJournalAccount () {
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          console.log(this.addForm);
          // this.addForm.revenuePaymentsId =  this.addForm.revenuePaymentsId.id;
          let params = {
            ...this.addForm
          }
          this.addLoading = true
          addRevenuePaymentDetail(params).then(res => {
            if (res.data.success) {
              this.$message({
                message: res.data.message,
                type: "success"
              });
              this.getDataList();
              // this.addDialogClose();
              this.dialog.journalAccount.show = false;
            } else {
              this.$message({
                message: res.data.message,
                type: "error"
              });
            }
            this.addLoading = false
          })

        }
      })
    },
    handleSelect (val) {
      this.addForm.revenuePaymentsId = val.id;
      console.log(val, 'val');
    },
    getChangePaymentsByNoMatchList (queryString, cb) {
      getPaymentsByNoMatchList({ keyWord: queryString }).then(res => {
        if (res.data.success) {
          this.revenuePaymentData = res.data.response;
          let revenuePaymentData = this.revenuePaymentData;
          let results = revenuePaymentData;
          cb(results);
        }
      })
    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.paymentName.indexOf(queryString) === 0);
      };
    },
    // 查询
    getListData () {
      this.pages.pageIndex = 1
      this.getDataList();
    },
    // 点击table某一行
    currentChange (val) {
      if (val) {
        this.needData = val
        console.log(val,'列表')
      }
    },
    // 点击汇总某一行
    currentChangeAll (val) {
      if (val) {
        this.needDataAll = val
        console.log(val,'汇总某行')
      }
    },
    // 分页
    handleCurrentChange (val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange (val) {
      this.pages.pageSize = val;
      this.getDataList();
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row.NoticeTime || row.NoticeTime == ""
          ? ""
          : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
    },
    rowClass ({ columnIndex }) {
      return 'padding:8px!important;'
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  }
}
</script>

<style lang="stylus" scoped>

</style>
